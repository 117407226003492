<template>
    <div class="statistic-block">
        <div class="df df-space-between">
            <div class="text-size-xxl text-bold df">
                <span class="mr8 free-requests-title">Бесплатные заявки</span>
                <tooltip
                        :direction="'right-center-direction'"
                        :is-btn-close="false"
                        :add-style="{width: 268 + 'px'}">
                    <template v-slot:tooltipBtn>
                        <img src="@/assets/img/info-blue.svg" alt="" class="info-icon">
                    </template>

                    <template v-slot:tooltipContent>
                        Этот блок показывает бесплатные <br> заявки за весь период с 24.08.2021
                    </template>
                </tooltip>
            </div>
            <div class="year-select ml24">
                <div class="arrow left" @click="currentYearIndex - 1 < 0 ? currentYearIndex = 0  : currentYearIndex = currentYearIndex - 1;monthsShortCurrentIndex = 0"></div>
                <div class="text-size-xxl text-bold text-center year">
                    {{ yearsFreeRequest[currentYearIndex]}}
                </div>
                <div class="arrow right" @click="currentYearIndex + 1 < yearsFreeRequest.length ? currentYearIndex += 1 : currentYearIndex;monthsShortCurrentIndex = 0"></div>
            </div>
            <tumbler  class="free-requests-tumbler" :options="optionsTeam" :elem-width="70" v-model="form.optionFreeRequests.value"/>
            <div class="text-size-xxl text-bold">
                {{ totalTeam.all }}
            </div>
        </div>
        <div class="graph graph-requests-free" v-if="totalTeam.new !== undefined">
            <div class="graph-item">
                <div class="graph-item__name">
                    Новые / Думают
                </div>
                <div class="graph-item__value">
                    <span><b>{{ totalTeam.new }}</b> / {{ totalTeam.newDays }} дн.</span>
                    <span><b>{{ totalTeam.think }}</b> / {{ totalTeam.thinkDays }} дн.</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Десктоп
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalTeam.desktop }}</span>
                    <span>{{ totalTeam.percent.desktop }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Мобайл
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalTeam.mobile }}</span>
                    <span>{{ totalTeam.percent.mobile }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Корректные заявки
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalTeam.correct }}</span>
                    <span>{{ totalTeam.percent.correct }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Спам (без отзвона)
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{totalTeam.refuseSpam}}</span>
                    <span>{{totalTeam.percent.refuseSpam}}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Выставлен счёт
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalTeam.invoiced}}</span>
                    <span>{{ totalTeam.percent.invoiced}}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Отклонены
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalTeam.refuse}}</span>
                    <span>{{ totalTeam.percent.refuse}}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Оплаченные
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalTeam.payed}}</span>
                    <span>{{ totalTeam.percent.payed}}%</span>
                    <span>{{ totalTeam.percent.payedAll}}% от {{ totalTeam.all }}</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Неоплаченные
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalTeam.refusePay}}</span>
                    <span>{{ totalTeam.percent.refusePay}}%</span>
                </div>
            </div>

            <div class="graph-item">
                <div class="graph-item__name">
                    Обычные
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalTeam.normal }}</span>
                    <span>{{ totalTeam.percent.normal }}%</span>
                </div>
            </div>
            <div class="graph-item">
                <div class="graph-item__name">
                    Дистанционные
                </div>
                <div class="graph-item__value">
                    <span class="text-bold">{{ totalTeam.remote }}</span>
                    <span>{{ totalTeam.percent.remote }}%</span>
                </div>
            </div>
            <div class="reasons">
                <div class="text-size-18 text-bold">
                    Причины отклонения <span style="display: none;">{{ getAllCount(reasonRejectTeam) }}</span>
                </div>
                <div class="df df-space-between mt8" v-for="reasonReject in reasonRejectTeam">
                    {{ reasonReject.reason }}
                    <div class="data df">
                        <div class="text-right text-color-grey text-size-xs mr8">{{ reasonReject.percent }}%</div>
                        <div class="text-right" style="width: 33px;">{{ reasonReject.count }}</div>
                    </div>
                </div>
            </div>
            <div class="reasons-months">
                <div class="reasons-months-item mt8"
                     @click="!(yearsFreeRequest[currentYearIndex]==currentYearDate && monthsShortCurrentIndex !== index && (currentMonth+1) < index) ? monthsShortCurrentIndex = index: false"
                     :class="{'link':monthsShortCurrentIndex !== index,'text-color-grey':yearsFreeRequest[currentYearIndex]==currentYearDate && monthsShortCurrentIndex !== index && (currentMonth+1) < index,'hover':!(yearsFreeRequest[currentYearIndex]==currentYearDate && monthsShortCurrentIndex !== index && (currentMonth+1) < index)}"
                     v-for="(month,index) in monthsShort"
                >{{month}}</div>
            </div>
        </div>
        <div class="graph-item outside-block" >
            <div class="graph-item__name">
                Отменили заявку, а потом передумали
            </div>
            <div class="graph-item__value" v-if="yearsFreeRequest[currentYearIndex]">
                <span class="text-bold">{{total[yearsFreeRequest[currentYearIndex]]['all'].returnReject }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import Tooltip from '@/components/Tooltip.vue';
import {number_format} from '@/helpers/formatted.js';
import Tumbler from '@/components/Tumbler.vue';

export default {
    name: "FreeRequests",
    components: {
        Tooltip,
        Tumbler,
    },
    data: function(){
        let date = new Date();
        return {
            interval: false,
            currentYearIndex: -1 ,
            currentYearDate: date.getFullYear(),
            currentMonth: date.getMonth(),
            months: ["ЯНВАРЬ","ФЕВРАЛЬ","МАРТ","АПРЕЛЬ","МАЙ","ИЮНЬ","ИЮЛЬ","АВГУСТ","СЕНТЯБРЬ","ОКТЯБРЬ","НОЯБРЬ","ДЕКАБРЬ"],
            monthsShort: ["ВСЕ","ЯНВ","ФЕВ","МАР","АПР","МАЙ","ИЮН","ИЮЛ","АВГ","СЕН","ОКТ","НОЯ","ДЕК"],
            monthsShortCurrentIndex: 0,
            refuseHistoryBtn: false,
            form: {
                optionFreeRequests: {
                    value: {id: 'all', name: 'Все'},
                    error: false,
                    repeatError: false,
                },
            },
            isDevelop: process.env.NODE_ENV === 'development'
        }
    },
    mounted(){
        this.currentYearIndex = this.yearsFreeRequest.indexOf(this.currentYearDate);
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            total: state => state.statistic.free,
            user: state => state.auth.user,
            optionsProject: state => state.options.items,
            yearsFreeRequest: state => state.statistic.yearsFree,
        }),
        optionsTeam() {
            let items = [];
            for(let t in this.optionsProject.teams){
                items.push({
                    id: this.optionsProject.teams[t].xml_id,
                    name: this.optionsProject.teams[t].name,
                });
            }
            return items;
        },
        currentYear() {
            return this.yearsFreeRequest[this.currentYearIndex];
        },
        totalTeam(){
            return this.currentYear && this.total && this.total[this.currentYear][this.form.optionFreeRequests.value.id] ? this.total[this.currentYear][this.form.optionFreeRequests.value.id] : {}
        },
        reasonRejectTeam(){
            if (this.totalTeam !== {}) {
                if (this.monthsShortCurrentIndex > 0) {
                    return this.totalTeam.reasonRejectMonth && this.totalTeam.reasonRejectMonth[this.monthsShortCurrentIndex] ? this.totalTeam.reasonRejectMonth[this.monthsShortCurrentIndex] : []
                } else {
                    return this.totalTeam.reasonReject;
                }
            }
        },
    },
    methods: {
        getAllCount(arr) {
            let count = 0;
            for(let i in arr) {
                count += parseInt(arr[i].count);
            }

            return count;
        },
        round(value){
            value = value.toFixed();
            let len = value.toString().length;
            if(len < 5){
                return Math.pow(10, len);
            } else {
                let m = Math.pow(10, len - 1 || 0);
                return Math.ceil(value / m) * m;
            }

        },
        number_format: function (v) {
            return number_format(v, 0, '', ' ')
        },
    },
}
</script>

<style scoped>

</style>
